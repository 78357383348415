import React from "react"
import { Link, graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons"
import {
  Jumbotron,
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardSubtitle,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Background from "../templates/thailand.jpg"
const InsurancePage = () => {
  return (
    <Layout>
      <SEO title="CIGNA Insurance" />
      <Jumbotron style={{ backgroundImage: `url(${Background})` }}>
        <Container
          fluid
          style={{
            backgroundColor: "rgb(249, 253, 180, 0.4)",
            paddingTop: 75,
            paddingBottom: 75,
          }}
        >
          <Container>
            <h1 className="display-3">CIGNA Insurance</h1>
            <p className="lead">Long Term Insurance</p>
          </Container>
        </Container>
      </Jumbotron>
      <Container>
        <>
          <Row style={{ marginTop: 20 }}>
            <Col md={12}>
              <h1>Thailand Only Coverage</h1>
              <p>Coming Soon</p>
            </Col>
          </Row>
          <hr className="my-2" />
          <Row style={{ marginTop: 20 }}>
            <Col md={8}>
              <h1>Expat Global</h1>
              <p>Apply any age, lifetime renewability</p>
            </Col>
            <Col md={4}></Col>
            <Col md={12} style={{ marginBottom: 20 }}>
              <Row>
                <Col md={6}>
                  <Button color="link">View Brochure</Button>
                </Col>
                <Col md={6}>
                  <Button color="link">View Policy Rules</Button>
                </Col>
                <Col md={6}>
                  <Button color="link">View Application</Button>
                </Col>
                <Col md={6}>
                  <Button color="link">View Customer Guide</Button>
                </Col>
              </Row>
            </Col>

            <Col md={12}>
              <Button color="success">Get Quote</Button>
            </Col>
          </Row>
          <br />
          <hr className="my-2" />
          <Row style={{ marginTop: 20 }}>
            <Col md={8}>
              <h1>Expat Close Care</h1>
              <p>
                Apply any age, lifetime renewability, coverage in home country
                and country of residence, aswell as 45 days of emergency care in
                other countries. Lower premiums - few lower benefits
              </p>
            </Col>
            <Col md={4}></Col>
            <Col md={12} style={{ marginBottom: 20 }}>
              <Row>
                <Col md={6}>
                  <Button color="link">View Brochure</Button>
                </Col>
                <Col md={6}>
                  <Button color="link">View Policy Rules</Button>
                </Col>
                <Col md={6}>
                  <Button color="link">View Application</Button>
                </Col>
                <Col md={6}>
                  <Button color="link">View Customer Guide</Button>
                </Col>
              </Row>
            </Col>

            <Col md={12}>
              <Button color="success">Get Quote</Button>
            </Col>
          </Row>
          <br />
        </>
      </Container>
    </Layout>
  )
}

export default InsurancePage
